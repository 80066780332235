import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProjectImage = ({ image, alt }) => {
	const { allFile } = useStaticQuery(
		graphql`
			query {
				allFile(filter: {sourceInstanceName: {eq: "images"}, extension: {ne: "svg"}}) {
					edges {
						node {
							childImageSharp {
								gatsbyImageData(placeholder: BLURRED, width: 900)
							}
							name,
							ext
						}
					}
				}
			}
		`
	);

	const imageInfo = allFile.edges.find((f) => `${f.node.name}${f.node.ext}` === image);
	const img = getImage(imageInfo.node);

	return (
		image ? <GatsbyImage image={img} alt={alt ?? "Project Image"} objectFit="contain" objectPosition="center center" /> : null
	);
};

export default ProjectImage;
