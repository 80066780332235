import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const StyledComponent = styled.div`
	position: relative;
	height: 100%;

	.gatsby-image-wrapper {
		height: 100%;

		img {
			width: ${({ cover }) => (cover ? "100%" : "80%")};
			height: ${({ cover }) => (cover ? "100%" : "80%")};
			object-position: center;
			object-fit: ${({ cover }) => (cover ? "cover" : "contain")};
			padding: 0 !important;
		}
	}

	section {
		width: 100%;
		height: 100%;
	}
`;

const BackgroundSection = ({
	className, image, key, alt, ...rest
}) => {
	const { allFile } = useStaticQuery(
		graphql`
			query {
				allFile(filter: {sourceInstanceName: {eq: "images"}, extension: {ne: "svg"}}) {
					edges {
					  	node {
							childImageSharp {
								gatsbyImageData(placeholder: BLURRED, width: 900)
							}
							name,
							ext
						}
					}
				}
			}
		`
	);
	const imageInfo = allFile.edges.find((f) => `${f.node.name}${f.node.ext}` === image);
	const img = getImage(imageInfo.node);
	return (
		<StyledComponent {...rest}>
			<GatsbyImage image={img} alt={alt} />
		</StyledComponent>
	);
};

export default BackgroundSection;
