import React from "react";
import StyledBackgroundSection from "components/StyledBackgroundSection";
import ProjectImage from "components/ProjectImage";

import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

import Flex from "components/Flex";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper-bundle.css";
import ExternalLink from "./ExternalLink";

// install Swiper modules
SwiperCore.use([Navigation]);

const StyledComponent = styled.div`
	position: relative; 
	display: flex;    
	flex-direction: column;
	width: 100%;

	height: 0;
	padding-top: calc(10 / 16  * 100%);

	margin-top: 2rem;
	margin-bottom: 5rem;

	@media (max-width: 768px) {
		padding-top: 50vh;
		margin-top: 1rem;
		margin-bottom: 3rem;
	}

	.project-slider {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.swiper-slide {
		display: flex;
		flex-direction: column;
		background-color: ${(props) => (props.colours[0] ? props.colours[0] : "white")};
		color: ${(props) => (props.colours[1] ? props.colours[1] : "black")} !important;
		padding: 0;
		justify-content: center;
		
		h1,h2,h3,h4 {
			color: ${(props) => (props.colours[1] ? props.colours[1] : "black")} !important;
		}

		img {
			padding: 0.5rem;
			filter: drop-shadow(0 0.2rem 0.3rem rgba(0,0,0,0.15));
		}
	}

	.padded-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 100%;
		padding: 3rem;

		@media (max-width: 768px) {
			padding: 1.5rem;
		}

		a:link {
			margin-left: 0;
		}

		.tag {
			display: inline;
			width: fit-content;
			background: rgba(0,0,0,0.06);
			padding: 0rem 0.3rem;
			border-radius: 3px;
			text-transform: uppercase;
			font-size: 0.7rem;
			margin-right: 0.2rem;
			margin-bottom: 0.2rem;
		}
		
	}

	.swiper-button-next, .swiper-button-prev {
		color: #fff;
	}

	a:link {
		margin-left: 1rem;
	}

`;

const ProjectSlider = ({
	slides, tags, title, client, website, app, ...rest
}) => {
	const returnSlides = slides.map(([imagePath, type, alt]) => {
		switch (type) {
			case "cover": {
				return (
					<SwiperSlide>
						<StyledBackgroundSection image={imagePath} alt={alt} cover />
					</SwiperSlide>
				);
			}
			case "contain": {
				return (
					<SwiperSlide style={{ padding: "2rem" }}>
						<ProjectImage image={imagePath} alt={alt} />
					</SwiperSlide>
				);
			}
			case "html": {
				return (
					<SwiperSlide>
						<div className="padded-content" dangerouslySetInnerHTML={{ __html: imagePath }} />
					</SwiperSlide>
				);
			}
			default: return null;
		}
	}).filter((sl) => sl !== null && sl !== undefined);

	const returnedTags = tags.map((value, index) => <div className="tag" alt={index}>{value}</div>);

	returnSlides.push(
		<SwiperSlide>
			<div className="padded-content">
				<h4 style={{ marginBottom: 0 }}>{title}</h4>
				<p>{client}</p>

				<Flex flexDirection="row" width="100%" flexWrap="wrap" marginBottom="2rem">
					{returnedTags}
				</Flex>

				{website ? <ExternalLink to={website}>→ Website</ExternalLink> : null}
				{app ? <ExternalLink to={app}>→ App</ExternalLink> : null}
			</div>
		</SwiperSlide>
	);

	return (
		<StyledComponent {...rest}>
			<Swiper
				navigation
				spaceBetween={5}
				slidesPerView={1.08}
				grabCursor
				// cssMode={true} // Add only for mobile versions
				className="project-slider"
				mousewheel
			>
				{returnSlides}
			</Swiper>

			<Flex
				flexDirection="row"
				justifyContent="space-between"
				style={{ marginTop: "0.5rem" }}
			>
				<h4 className="ellipsis">{title}</h4>

				<div>
					{website ? <ExternalLink to={website}>→ Website</ExternalLink> : null}
					{app ? <ExternalLink to={app}>→ App</ExternalLink> : null}
				</div>
			</Flex>

		</StyledComponent>
	);
};

export default ProjectSlider;
